import me from './assets/images/me.png';
import jedi from './assets/images/jedi.png';
import camelot from './assets/images/camelot.png';
import gas from './assets/images/gas.png';
import s3vr from './assets/images/s3vr.png';
import eye from './assets/images/eye.png';
import eurydice from './assets/images/eurydice.png';

const logotext = "YULIN";
const meta = {
    title: "Yulin Zhao",
    description: "Full Stack | Machine Learning | Data Scientist | Current UIUC MCS Student",
};

const introdata = {
    title: "I'm Yulin Zhao",
    animated: {
        first: "I change the world",
    },
    description: "Full Stack | Machine Learning | Data Scientist | Current UIUC MCS Student",
    your_img_url: me,
};

const dataabout = {
    title: "Interests",
    aboutme: "Enjoy changing the world by making decisions based on patterns mined from large-scale data",
};
const worktimeline = [{
        jobtitle: "Full Stack Machine Learning Intern",
        where: "Ensaras, Inc.",
        date: "2023",
    },
    {
        jobtitle: "Data Science Intern",
        where: "Forkaia®",
        date: "2022",
    },
    {
        jobtitle: "Data Scientist Intern",
        where: "DeFiner, Inc.",
        date: "2021",
    },
    {
        jobtitle: "Teaching Assistant",
        where: "Case Western Reserve University",
        date: "2019",
    },
    {
        jobtitle: "Peer Tutor",
        where: "Case Western Reserve University",
        date: "2018",
    },
];

const skills = {
    'part1': [{
            name: "Python",
            value: 95,
        },
        {
            name: "SQL",
            value: 93,
        },
        {
            name: "Javascript",
            value: 85,
        },
        {
            name: "Java",
            value: 75,
        },
    ],
    'part2': [{
            name: "AWS",
            value: 90,
        },
        {
            name: "Django",
            value: 85,
        },
        {
            name: "React",
            value: 83,
        },
        {
            name: "PostgreSQL",
            value: 91,
        },
        {
            name: "PyTorch",
            value: 93,
        },
    ],    
};

const services = [{
        title: "Full Stack Development",
        description: "Experienced with Python Django/Flask backend, React/Angular frontend, AWS/Azure cloud platform deployment, and PostgreSQL/MySQL/MongoDB database connection.",
    },
    {
        title: "Machine Learning",
        description: "Experienced with classical ML models and deep learning models in CV, NLP, time-series, graphs, and adversarial learning.",
    },
    {
        title: "Data Science",
        description: "Experienced with data collection, engineering, analytics, and  ",
    },
];

const dataportfolio = [{
        img: jedi,
        description: "Job Jedi helps to create highly relevant resumes for job applications using GPT technology",
        link: "https://github.com/vanity-lost/Job-Jedi",
    },
    {
        img: camelot,
        description: "Reproduction and Ablation Study of CAMELOT",
        link: "https://github.com/vanity-lost/CAMELOT-Reproduction",
    },
    {
        img: gas,
        description: "Predict the gasoline price using LSTM",
        link: "https://github.com/vanity-lost/Gasoline-Price-Prediction",
    },
    {
        img: s3vr,
        description: "Semi-supervised SVR for English Essay Evaluation",
        link: "https://github.com/vanity-lost/Evaluating-language-Learning",
    },
    {
        img: eye,
        description: "Multi-task Eye Disease Classification Model",
        link: "https://github.com/cathyli247/CSDS312",
    },
    {
        img: eurydice,
        description: "Unity 3D Horror Game",
        link: "https://github.com/vanity-lost/CSDS390-Project",
    },
];

const contactConfig = {
    YOUR_EMAIL: "yulinzhao.jobs@outlook.com",
    YOUR_FONE: "(216)298-3872",
    description: "Prefer to be contacted by email or message",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/vanity-lost",
    linkedin: "https://linkedin.com/in/randolph-zhao",
};

export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};