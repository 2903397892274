import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { logotext ,socialprofils } from "../content_option";
import Themetoggle from "../components/themetoggle";

const Headermain = () => {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
    // document.body.classList.toggle("ovhidden");
  };

  return (
    <>
      <header className="fixed-top site__header">
        <div className="d-flex align-items-center justify-content-between">
          <Link  className="navbar-brand nav_ac" to="/">
            {logotext}
          </Link>

          <ul className="the_menu">
            <li className="menu_item">
              <Link  onClick={handleToggle} to="/" className="my-3">Home</Link>
            </li>
            <li className="menu_item">
              <Link  onClick={handleToggle} to="/portfolio" className="my-3"> Portfolio</Link>
            </li>
            <li className="menu_item">
              <Link onClick={handleToggle} to="/about" className="my-3">About</Link>
            </li>
            <li className="menu_item">
              <Link onClick={handleToggle} to="/contact" className="my-3"> Contact</Link>
            </li>
          </ul>

          <div className="d-flex align-items-center">
            <Themetoggle />
          </div>
        </div>

      </header>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>
      
    </>
  );
};

export default Headermain;
